<template>
  <!-- <div style="height: calc(100% - 82px); position: relative; width: 100%;"> -->
  <div style="height: 100%; position: relative; width: 100%">
    <div v-if="bridgeNull">ไม่มีข้อมูลของชิ้นส่วนสะพานนี้</div>
    <div v-else class="profile-view">
      <v-card
        id="legend"
        class="d-flex flex-column pa-3"
        style="
          position: absolute;
          bottom: 5px;
          right: 5px;
          background: white;
          z-index: 5;
        "
        v-if="isIndept == true && bridgeView != 'sideView'"
        outlined
      >
        <div class="pr-3 d-flex flex-row">
          <img :src="imgPath.checkMarkGreen" width="18" height="18" />
          <div class="pl-6" style="font-size: 14px">ตรวจสอบครบแล้ว</div>
        </div>
        <div class="d-flex flex-row">
          <img :src="imgPath.checkMarkMinus" width="18" height="18" />
          <div class="pl-6" style="font-size: 14px">ตรวจสอบแล้วบางส่วน</div>
        </div>
        <div class="d-flex flex-row">
          <img :src="imgPath.checkMarkGrey" width="18" height="18" />
          <div class="pl-6" style="font-size: 14px">ยังไม่มีการตรวจสอบ</div>
        </div>
      </v-card>
      <svg v-show="finishLoading" width="100%" height="100%" id="bridge">
        <!-- #bridge -->
        <g></g>
      </svg>
      <v-row
        v-show="!finishLoading"
        align="center"
        class="fill-height ma-0"
        justify="center"
      >
        <v-progress-circular
          color="grey lighten-2"
          indeterminate
        ></v-progress-circular>
      </v-row>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { mapState } from 'vuex'
export default {
  props: {
    value: Boolean,
    bridgePart: Array,
    bridgeType: String,
    bridgeView: String,
    bridgeDirection: String,
    startLocation: String,
    endLocation: String,
    brFlag: Number,
    imageDirection: String,
    disableDirection: Boolean,
    accidentMode: Boolean,
    accidentType: String,
    routineDamageImage: Array,
    isIndept: Boolean,
    bridgeSubPartComplete: Array,
    indeptSectorComplete: Object,
    bridgeSelected: Object,
    has_a1: Boolean,
    has_a2: Boolean,
    selectedPart: String
  },
  data: () => ({
    imgPath: {
      valueInside: false,
      bottom_a_ww_left: require('@/assets/img/bridge_component/bottom/a_ww_left.png'),
      bottom_a_ww_right: require('@/assets/img/bridge_component/bottom/a_ww_right.png'),
      bottom_br: require('@/assets/img/bridge_component/bottom/br.png'),
      bottom_slab: require('@/assets/img/bridge_component/bottom/slab.png'),
      crossBG: require('@/assets/img/bridge_component_svg/cross_section/gd_box.svg'),
      crossIG: require('@/assets/img/bridge_component_svg/cross_section/gd_i.svg'),
      // defaultDamageImage: require('@/assets/img/bridge_component_svg/Camera@3x.png')
      defaultDamageImage: require('@/assets/img/bridge_component_svg/camera60x.svg'),
      sideALeft: require('@/assets/img/bridge_component_svg/side/a_left.svg'),
      sideARight: require('@/assets/img/bridge_component_svg/side/a_right.svg'),
      sideP: require('@/assets/img/bridge_component_svg/side/p.svg'),
      checkMarkGrey: require('@/assets/img/check-mark-grey2.svg'),
      checkMarkGreen: require('@/assets/img/check-mark-green2.svg'),
      checkMarkMinus: require('@/assets/img/check-mark-grey3.svg')
    },
    bridgeDamageImagePath: [],
    data: [],
    zoom: 0,
    height: 1000,
    width: 1000,
    translateX: 750,
    globalWidth: 0,
    globalHeight: 0,
    lastItemId: undefined,
    lastItemColor: undefined,
    hoverItem: undefined,
    beforeHoverItem: undefined,
    bridgeNull: false,
    finishLoading: false,
    checkallow: {}
  }),
  mounted () {
    this.valueInside = this.value
    this.width = document.querySelector('.profile-view').clientWidth
    this.height = document.querySelector('.profile-view').clientHeight
    this.initZoom()
    setTimeout(() => {
      var all = document.getElementsByClassName('profile-view')
      for (var i = 0; i < all.length; i++) {
        all[i].style.width = '100%'
      }
    }, 200)
  },
  computed: {
    ...mapState('inspectionDamageDialog', {
      inspectionType: 'inspectionType'
    })
  },
  methods: {
    handleZoom (e) {
      if (this.imageDirection === 'vertical') {
        // const res = d3.select('svg g').attr('transform', e.transform + ' rotate(-90, ' + this.globalWidth + ', ' + this.globalHeight + ')')
        d3.select('svg g').attr(
          'transform',
          e.transform +
            ' rotate(-90, ' +
            this.globalWidth +
            ', ' +
            this.globalHeight +
            ')'
        )
        // console.log('in handle')
        // console.log(res)
        // d3.select('svg g').attr('transform', e.transform + ' rotate(-90)')
        // d3.select('svg g').attr('transform', e.transform)
      } else {
        d3.select('svg g').attr('transform', e.transform)
      }
    },
    zoomFit (g) {
      const bounds = g.node().getBBox()
      const parent = g.node().parentElement
      let fullWidth = parent.clientWidth || parent.parentNode.clientWidth
      let fullHeight = parent.clientHeight || parent.parentNode.clientHeight
      let width = bounds.width
      let height = bounds.height + 100
      let midX = bounds.x + width / 2
      let midY = bounds.y + height / 2
      if (this.imageDirection === 'vertical') {
        fullWidth = parent.clientWidth || parent.parentNode.clientWidth
        fullHeight = parent.clientHeight || parent.parentNode.clientHeight
        width = bounds.height
        height = bounds.width
        midX = bounds.y + height / 2
        midY = bounds.x + width / 2
      }
      let scale = 0.9 / Math.max(width / fullWidth, height / fullHeight)
      let translate = [
        fullWidth / 2 - scale * midX,
        fullHeight / 2 - scale * midY
      ]
      if (width === 0 || height === 0) {
        scale = 1
        translate = [0, 0]
      }
      const transform = d3.zoomIdentity
        .translate(translate[0], translate[1])
        .scale(scale)
      return transform
    },
    changeColor (id) {
      if (this.lastItemId !== undefined && this.lastItemColor !== undefined) {
        d3.select('#' + this.lastItemId).attr('style', this.beforeHoverItem)
        // d3.select('#' + this.lastItemId).attr('style', this.lastItemColor)
      }
      this.lastItemId = id
      this.lastItemColor = d3.select('#' + id).attr('style')
      d3.select('#' + id).attr(
        'style',
        'fill:#BEDF76;stroke-width:3;stroke:rgb(0,0,0)'
      )
    },
    async checkAllow (componentId) {
      if (!(componentId in this.checkallow)) {
        this.checkallow[componentId] = await this.$store.dispatch(
          'bridgeDetail/checkIsAllowed',
          {
            componentId,
            inspectionType: this.inspectionType
          }
        )
      }
      return this.checkallow[componentId]
    },
    mouseovered (active, id, checkallow = true) {
      return (event, d) => {
        if (checkallow) {
          if (active && this.lastItemId !== id) {
            this.beforeHoverItem = d3.select('#' + id).attr('style')
            d3.select('#' + id).attr(
              'style',
              'fill:#eef3ff;stroke-width:3;stroke:rgb(0,0,0)'
            )
          } else if (!active && this.lastItemId !== id) {
            d3.select('#' + id).attr('style', this.beforeHoverItem)
          }
        }
      }
    },
    async initZoom () {
      this.finishLoading = false
      const bridgeAvailable = await this.getBridgeSegment(
        this.$route.query.id,
        this.bridgeSelected.partcode,
        1
      )
      if (!bridgeAvailable) {
        console.log('bridge component is null !!!')
        this.bridgeNull = true
      } else {
        let checkallow = false
        if (this.accidentMode) {
          this.bridgeDamageImagePath = []
          if (this.routineDamageImage != null) {
            if (this.accidentType === 'overview') {
              for (let i = 1; i <= 9; i++) {
                this.bridgeDamageImagePath.push(null)
              }
              for (let i = 0; i < this.routineDamageImage.length; i++) {
                this.bridgeDamageImagePath[
                  parseInt(this.routineDamageImage[i].seq) - 1
                ] = this.routineDamageImage[i].thumbnail
              }
            } else {
              if (this.bridgeView === 'topView') {
                for (
                  let i = 0;
                  i < (this.bridgePart.length - 2) * 8 + 12;
                  i++
                ) {
                  this.bridgeDamageImagePath.push(null)
                }
                for (let i = 0; i < this.routineDamageImage.length; i++) {
                  this.bridgeDamageImagePath[
                    parseInt(this.routineDamageImage[i].seq) - 1
                  ] = this.routineDamageImage[i].thumbnail
                }
              } else if (this.bridgeView === 'bottomView') {
                for (let i = 0; i < (this.bridgePart.length - 2) * 8 + 4; i++) {
                  this.bridgeDamageImagePath.push(null)
                }
                for (let i = 0; i < this.routineDamageImage.length; i++) {
                  this.bridgeDamageImagePath[
                    parseInt(this.routineDamageImage[i].seq) - 1
                  ] = this.routineDamageImage[i].thumbnail
                }
              }
            }
          }
        }
        this.translateX = 0
        d3.selectAll('svg g').remove()
        d3.select('#bridge').append('g')

        const self = this

        const g = d3.select('g')

        let start = 300
        let end = 400
        let jointText = 'J'
        let clickParams = {}

        let styleColor =
          'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
        const selectColor =
          'fill:rgb(255,69,0);stroke-width:1;stroke:rgb(0,0,0)'
        if (this.bridgeView === 'topView') {
          jointText = 'J'
        } else if (this.bridgeView === 'bottomView') {
          jointText = 'P'
        }
        for (let i = 0; i < this.bridgePart.length; i++) {
          if (
            this.bridgePart[i].segment === 'A' &&
            this.bridgePart[i].seq === 1
          ) {
            if (this.bridgeView === 'topView') {
              const idSegment = `${this.bridgePart[i].segment}${this.bridgePart[i].seq}`
              g.append('polygon')
                .attr('id', function () {
                  return idSegment
                })
                .style('stroke', 'black')
                .style('fill', 'rgb(255, 255, 255)') // white
                .style('stroke-width', 3)
                .on('mouseover', self.mouseovered(true, idSegment))
                .on('mouseout', self.mouseovered(false, idSegment))
                .attr(
                  'points',
                  start.toString() +
                    ',150 ' +
                    start.toString() +
                    ',400 ' +
                    (end - 10).toString() +
                    ',350 ' +
                    (end - 10).toString() +
                    ',200'
                )
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment +
                      self.bridgePart[i].seq.toString(),
                    true,
                    false
                  )
                })
              g.append('text')
                .text(
                  this.bridgePart[i].segment + this.bridgePart[i].seq.toString()
                )
                .attr('x', (start + end - 20) / 2)
                .attr('y', 275)
                .on('mouseover', self.mouseovered(true, idSegment))
                .on('mouseout', self.mouseovered(false, idSegment))
                .attr('class', 'textLayer')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment +
                      self.bridgePart[i].seq.toString(),
                    true,
                    false
                  )
                })
              if (this.isIndept) {
                clickParams.sectorID = self.bridgePart[i].segment
                clickParams.sectorNo = self.bridgePart[i].seq
                clickParams.componentID = self.bridgePart[i].segment
                clickParams.componentNo = self.bridgePart[i].seq
                clickParams.text =
                  self.bridgePart[i].segment + self.bridgePart[i].seq.toString()
                clickParams.back = true
                clickParams.action = false
                self.drawCheckMark(
                  g,
                  this.bridgePart[i].isCompleted,
                  (start + end - 20) / 2,
                  230,
                  20,
                  clickParams,
                  `${this.bridgePart[i].segment}${this.bridgePart[i].seq}`
                )
                clickParams = {}
              }
              g.append('polygon')
                .attr('id', function () {
                  return `J${self.bridgePart[i].seq}-EJ1`
                })
                .style('stroke', 'black')
                .style('fill', 'white')
                .style('stroke-width', 3)
                .attr('class', 'hoverItem')
                .on(
                  'mouseover',
                  self.mouseovered(true, `J${self.bridgePart[i].seq}-EJ1`)
                )
                .on(
                  'mouseout',
                  self.mouseovered(false, `J${self.bridgePart[i].seq}-EJ1`)
                )
                .attr(
                  'points',
                  (end - 10).toString() +
                    ',200 ' +
                    (end - 10).toString() +
                    ',350 ' +
                    end.toString() +
                    ',350 ' +
                    end.toString() +
                    ',200'
                )
                .on('click', function () {
                  self.changeColor(`J${self.bridgePart[i].seq}-EJ1`)
                  self.bridgeComponentClickObject(
                    'J',
                    self.bridgePart[i].seq,
                    'EJ',
                    1,
                    `J${self.bridgePart[i].seq}-EJ1`,
                    false,
                    true
                  )
                })
              g.append('text')
                .text(jointText + (i + 1).toString())
                .attr('x', end - 10)
                .attr('y', 380)
                .attr('class', 'textLayer')
                .attr('class', 'hoverItem')
                .on(
                  'mouseover',
                  self.mouseovered(true, `J${self.bridgePart[i].seq}-EJ1`)
                )
                .on(
                  'mouseout',
                  self.mouseovered(false, `J${self.bridgePart[i].seq}-EJ1`)
                )
                .on('click', function () {
                  self.changeColor(`J${self.bridgePart[i].seq}-EJ1`)
                  self.bridgeComponentClickObject(
                    'J',
                    self.bridgePart[i].seq,
                    'EJ',
                    1,
                    `J${self.bridgePart[i].seq}-EJ1`,
                    false,
                    true
                  )
                })
              if (this.isIndept) {
                clickParams.sectorID = 'J'
                clickParams.sectorNo = 1
                clickParams.componentID = 'EJ'
                clickParams.componentNo = self.bridgePart[i].seq
                clickParams.text = 'J' + self.bridgePart[i].seq.toString()
                clickParams.back = false
                clickParams.action = true
                self.drawCheckMark(
                  g,
                  this.bridgeSubPartComplete[0].isCompleted,
                  end - 10,
                  390,
                  20,
                  clickParams,
                  `J${self.bridgePart[i].seq}-EJ1`
                )
                clickParams = {}
              }
            } else if (this.bridgeView === 'bottomView') {
              const idSegment = `${this.bridgePart[i].segment}${this.bridgePart[i].seq}`
              g.append('polygon')
                .attr('id', function () {
                  return idSegment
                })
                .on('mouseover', self.mouseovered(true, idSegment))
                .on('mouseout', self.mouseovered(false, idSegment))
                .style('stroke', 'black')
                .style('fill', 'rgb(255, 255, 255)')
                .style('stroke-width', 3)
                .attr(
                  'points',
                  start.toString() +
                    ',150 ' +
                    start.toString() +
                    ',400 ' +
                    end.toString() +
                    ',350 ' +
                    end.toString() +
                    ',200'
                )
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment +
                      self.bridgePart[i].seq.toString(),
                    true,
                    false
                  )
                })
              g.append('text')
                .text(
                  this.bridgePart[i].segment + this.bridgePart[i].seq.toString()
                )
                .on('mouseover', self.mouseovered(true, idSegment))
                .on('mouseout', self.mouseovered(false, idSegment))
                .attr('x', (start + end - 20) / 2)
                .attr('y', 275)
                .attr('class', 'textLayer')
                .attr('class', 'hoverItem')
                // .on('click', function () { self.bridgeComponentClick(self.bridgePart[i]) })
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment +
                      self.bridgePart[i].seq.toString(),
                    true,
                    false
                  )
                })
              if (this.isIndept) {
                clickParams.sectorID = self.bridgePart[i].segment
                clickParams.sectorNo = self.bridgePart[i].seq
                clickParams.componentID = self.bridgePart[i].segment
                clickParams.componentNo = self.bridgePart[i].seq
                clickParams.text =
                  self.bridgePart[i].segment + self.bridgePart[i].seq.toString()
                clickParams.back = true
                clickParams.action = false
                self.drawCheckMark(
                  g,
                  this.bridgePart[i].isCompleted,
                  (start + end - 20) / 2,
                  230,
                  20,
                  clickParams,
                  `${this.bridgePart[i].segment}${this.bridgePart[i].seq}`
                )
                clickParams = {}
              }
            }
          } else if (this.bridgePart[i].segment === 'S') {
            if (this.bridgeView === 'topView') {
              const idSegment = `${self.bridgePart[i].segment}${self.bridgePart[i].seq}`
              g.append('polygon')
                .attr('id', function () {
                  return idSegment
                })
                .on('mouseover', self.mouseovered(true, idSegment))
                .on('mouseout', self.mouseovered(false, idSegment))
                .style('stroke', 'black')
                .style('fill', 'rgb(255, 255, 255)')
                .style('stroke-width', 3)
                .attr(
                  'points',
                  start.toString() +
                    ',200 ' +
                    start.toString() +
                    ',350 ' +
                    (end - 10).toString() +
                    ',350 ' +
                    (end - 10).toString() +
                    ',200'
                )
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment +
                      self.bridgePart[i].seq.toString(),
                    true,
                    false
                  )
                })
              g.append('text')
                .text(
                  this.bridgePart[i].segment + this.bridgePart[i].seq.toString()
                )
                .attr('x', (start + end - 20) / 2)
                .attr('y', 275)
                .on('mouseover', self.mouseovered(true, idSegment))
                .on('mouseout', self.mouseovered(false, idSegment))
                .attr('class', 'textLayer')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment +
                      self.bridgePart[i].seq.toString(),
                    true,
                    false
                  )
                })
              if (this.isIndept) {
                clickParams.sectorID = self.bridgePart[i].segment
                clickParams.sectorNo = self.bridgePart[i].seq
                clickParams.componentID = self.bridgePart[i].segment
                clickParams.componentNo = self.bridgePart[i].seq
                clickParams.text =
                  self.bridgePart[i].segment + self.bridgePart[i].seq.toString()
                clickParams.back = true
                clickParams.action = false
                self.drawCheckMark(
                  g,
                  this.bridgePart[i].isCompleted,
                  (start + end - 20) / 2,
                  230,
                  20,
                  clickParams,
                  `${self.bridgePart[i].segment}${self.bridgePart[i].seq}`
                )
                clickParams = {}
              }
              if (this.bridgePart[i + 1]) {
                // if (!(this.bridgePart[i + 1].segment === 'A' && this.bridgePart[i + 1].seq === 2)) {
                g.append('polygon')
                  .attr('id', function () {
                    return `J${i + 1}-EJ1`
                  })
                  .style('stroke', 'black')
                  .style('fill', 'white')
                  .style('stroke-width', 3)
                  .attr('class', 'hoverItem')
                  .on('mouseover', self.mouseovered(true, `J${i + 1}-EJ1`))
                  .on('mouseout', self.mouseovered(false, `J${i + 1}-EJ1`))
                  .attr(
                    'points',
                    (end - 10).toString() +
                      ',200 ' +
                      (end - 10).toString() +
                      ',350 ' +
                      end.toString() +
                      ',350 ' +
                      end.toString() +
                      ',200'
                  )
                  .on('click', function () {
                    self.changeColor(`J${i + 1}-EJ1`)
                    self.bridgeComponentClickObject(
                      'J',
                      i + 1,
                      'EJ',
                      1,
                      `J${i + 1}-EJ1`,
                      false,
                      true
                    )
                  })
                // }
                g.append('text')
                  .text(jointText + (i + 1).toString())
                  .attr('x', end - 10)
                  .attr('y', 380)
                  .attr('class', 'textLayer')
                  .attr('class', 'hoverItem')
                  .on('mouseover', self.mouseovered(true, `J${i + 1}-EJ1`))
                  .on('mouseout', self.mouseovered(false, `J${i + 1}-EJ1`))
                  .on('click', function () {
                    self.changeColor(`J${i + 1}-EJ1`)
                    self.bridgeComponentClickObject(
                      'J',
                      i + 1,
                      'EJ',
                      1,
                      `J${i + 1}-EJ1`,
                      false,
                      true
                    )
                  })
              }
              if (this.isIndept && this.bridgePart[i + 1]) {
                clickParams.sectorID = 'J'
                clickParams.sectorNo = parseInt(self.bridgePart[i].seq) + 1
                clickParams.componentID = 'EJ'
                clickParams.componentNo = 1
                clickParams.text =
                  'J' + (parseInt(self.bridgePart[i].seq) + 1).toString()
                clickParams.back = false
                clickParams.action = true
                self.drawCheckMark(
                  g,
                  this.bridgeSubPartComplete[i].isCompleted,
                  end - 10,
                  390,
                  20,
                  clickParams,
                  `J${i + 1}-EJ1`
                )
                clickParams = {}
              }
            } else if (this.bridgeView === 'bottomView') {
              const idSegment = `${this.bridgePart[i].segment}${this.bridgePart[i].seq}`
              g.append('polygon')
                .attr('id', function () {
                  return idSegment
                })
                .on(
                  'mouseover',
                  self.mouseovered(
                    true,
                    `${this.bridgePart[i].segment}${this.bridgePart[i].seq}`
                  )
                )
                .on(
                  'mouseout',
                  self.mouseovered(
                    false,
                    `${this.bridgePart[i].segment}${this.bridgePart[i].seq}`
                  )
                )
                .style('stroke', 'black')
                .style('fill', 'rgb(255, 255, 255)')
                .style('stroke-width', 3)
                .attr(
                  'points',
                  start.toString() +
                    ',200 ' +
                    start.toString() +
                    ',350 ' +
                    (end - 10).toString() +
                    ',350 ' +
                    (end - 10).toString() +
                    ',200'
                )
                .attr('class', 'hoverItem')
                // .on('click', function () { self.bridgeComponentClick(self.bridgePart[i]) })
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment +
                      self.bridgePart[i].seq.toString(),
                    true,
                    false
                  )
                })
              g.append('text')
                .text(`${this.bridgePart[i].segment}${this.bridgePart[i].seq}`)
                .on('mouseover', self.mouseovered(true, idSegment))
                .on('mouseout', self.mouseovered(false, idSegment))
                .attr('x', (start + end - 20) / 2)
                .attr('y', 275)
                .attr('class', 'textLayer')
                .attr('class', 'hoverItem')
                // .on('click', function () { self.bridgeComponentClick(self.bridgePart[i]) })
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment +
                      self.bridgePart[i].seq.toString(),
                    true,
                    false
                  )
                })
              if (this.isIndept) {
                clickParams.sectorID = self.bridgePart[i].segment
                clickParams.sectorNo = self.bridgePart[i].seq
                clickParams.componentID = self.bridgePart[i].segment
                clickParams.componentNo = self.bridgePart[i].seq
                clickParams.text = `${self.bridgePart[i].segment}${self.bridgePart[i].seq}`
                clickParams.back = true
                clickParams.action = false
                self.drawCheckMark(
                  g,
                  this.bridgePart[i].isCompleted,
                  (start + end - 20) / 2,
                  230,
                  20,
                  clickParams,
                  `${this.bridgePart[i].segment}${this.bridgePart[i].seq}`
                )
                clickParams = {}
              }
              if (this.bridgePart[i + 1]) {
                if (
                  this.bridgePart[i + 1].segment +
                    this.bridgePart[i + 1].seq.toString() !==
                  'A2'
                ) {
                  g.append('polygon')
                    .attr('id', function () {
                      return `${jointText}${self.bridgePart[i].seq}`
                    })
                    .on(
                      'mouseover',
                      self.mouseovered(
                        true,
                        `${jointText}${this.bridgePart[i].seq}`
                      )
                    )
                    .on(
                      'mouseout',
                      self.mouseovered(
                        false,
                        `${jointText}${this.bridgePart[i].seq}`
                      )
                    )
                    .style('stroke', 'black')
                    .style('fill', 'rgb(255, 255, 255)')
                    .style('stroke-width', 3)
                    .attr(
                      'points',
                      (end - 10).toString() +
                        ',200 ' +
                        (end - 10).toString() +
                        ',350 ' +
                        end.toString() +
                        ',350 ' +
                        end.toString() +
                        ',200'
                    )
                    .attr('class', 'hoverItem')
                    // .on('click', function () { self.bridgeComponentClick(jointText + (i + 1).toString()) })
                    .on('click', function () {
                      self.bridgeComponentClickObject(
                        jointText,
                        parseInt(self.bridgePart[i].seq),
                        jointText,
                        parseInt(self.bridgePart[i].seq),
                        jointText + i.toString(),
                        true,
                        false
                      )
                    })
                  g.append('text')
                    .text(jointText + self.bridgePart[i].seq.toString())
                    .on(
                      'mouseover',
                      self.mouseovered(
                        true,
                        `${jointText}${this.bridgePart[i].seq}`
                      )
                    )
                    .on(
                      'mouseout',
                      self.mouseovered(
                        false,
                        `${jointText}${this.bridgePart[i].seq}`
                      )
                    )
                    .attr('x', end - 10)
                    .attr('y', 370)
                    .attr('class', 'textLayer')
                    .attr('class', 'hoverItem')
                    // .on('click', function () { self.bridgeComponentClick(jointText + (i).toString()) })
                    .on('click', function () {
                      self.bridgeComponentClickObject(
                        jointText,
                        self.bridgePart[i].seq,
                        jointText,
                        self.bridgePart[i].seq,
                        `${jointText}${self.bridgePart[i].seq}`,
                        true,
                        false
                      )
                    })
                  if (this.isIndept) {
                    // clickParams.sectorID = jointText
                    // clickParams.sectorNo = this.bridgePart[i].seq
                    // clickParams.componentID = jointText
                    // clickParams.componentNo = this.bridgePart[i].seq
                    // clickParams.text = `${jointText}${this.bridgePart[i].seq}`
                    // clickParams.back = true
                    // clickParams.action = false

                    clickParams.sectorID = jointText
                    clickParams.sectorNo = this.bridgePart[i].seq
                    clickParams.componentID = jointText
                    clickParams.componentNo = this.bridgePart[i].seq
                    clickParams.text = jointText + i.toString()
                    clickParams.back = true
                    clickParams.action = false
                    const isCompleted = this.bridgeSubPartComplete.find(
                      (sub) => sub.seq === parseInt(this.bridgePart[i].seq)
                    ).isCompleted
                    self.drawCheckMark(
                      g,
                      isCompleted,
                      end - 10,
                      390,
                      20,
                      clickParams,
                      `${jointText}${this.bridgePart[i].seq}`
                    )
                    clickParams = {}
                  }
                }
              }
            }
          } else if (
            this.bridgePart[i].segment === 'A' &&
            this.bridgePart[i].seq === 2
          ) {
            if (this.bridgeView === 'topView') {
              const idSegment = `${this.bridgePart[i].segment}${this.bridgePart[i].seq}`
              g.append('polygon')
                .attr('id', function () {
                  return idSegment
                })
                .on('mouseover', self.mouseovered(true, idSegment))
                .on('mouseout', self.mouseovered(false, idSegment))
                .style('stroke', 'black')
                .style('fill', 'rgb(255, 255, 255)')
                .style('stroke-width', 3)
                .attr(
                  'points',
                  start.toString() +
                    ',350 ' +
                    start.toString() +
                    ',200 ' +
                    (end - 10).toString() +
                    ',150 ' +
                    (end - 10).toString() +
                    ',400'
                )
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment +
                      self.bridgePart[i].seq.toString(),
                    true,
                    false
                  )
                })
              g.append('text')
                .text(
                  this.bridgePart[i].segment + this.bridgePart[i].seq.toString()
                )
                .on('mouseover', self.mouseovered(true, idSegment))
                .on('mouseout', self.mouseovered(false, idSegment))
                .attr('x', (start + end - 20) / 2)
                .attr('y', 275)
                .attr('class', 'textLayer')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment +
                      self.bridgePart[i].seq.toString(),
                    true,
                    false
                  )
                })
              if (this.isIndept) {
                clickParams.sectorID = self.bridgePart[i].segment
                clickParams.sectorNo = self.bridgePart[i].seq
                clickParams.componentID = self.bridgePart[i].segment
                clickParams.componentNo = self.bridgePart[i].seq
                clickParams.text =
                  self.bridgePart[i].segment + self.bridgePart[i].seq.toString()
                clickParams.back = true
                clickParams.action = false
                self.drawCheckMark(
                  g,
                  this.bridgePart[i].isCompleted,
                  (start + end - 20) / 2,
                  230,
                  20,
                  clickParams,
                  `${this.bridgePart[i].segment}${this.bridgePart[i].seq}`
                )
                clickParams = {}
              }
            } else if (this.bridgeView === 'bottomView') {
              const idSegment = `${this.bridgePart[i].segment}${this.bridgePart[i].seq}`
              g.append('polygon')
                .attr('id', function () {
                  return idSegment
                })
                .on('mouseover', self.mouseovered(true, idSegment))
                .on('mouseout', self.mouseovered(false, idSegment))
                .style('stroke', 'black')
                .style('fill', 'rgb(255, 255, 255)')
                .style('stroke-width', 3)
                .attr(
                  'points',
                  (start - 10).toString() +
                    ',350 ' +
                    (start - 10).toString() +
                    ',200 ' +
                    (end - 10).toString() +
                    ',150 ' +
                    (end - 10).toString() +
                    ',400'
                )
                .attr('class', 'hoverItem')
                // .on('click', function () { self.bridgeComponentClick(self.bridgePart[i]) })
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment +
                      self.bridgePart[i].seq.toString(),
                    true,
                    false
                  )
                })
              g.append('text')
                .text(
                  this.bridgePart[i].segment + this.bridgePart[i].seq.toString()
                )
                .on('mouseover', self.mouseovered(true, idSegment))
                .on('mouseout', self.mouseovered(false, idSegment))
                .attr('x', (start + end - 20) / 2)
                .attr('y', 275)
                .attr('class', 'textLayer')
                .attr('class', 'hoverItem')
                // .on('click', function () { self.bridgeComponentClick(self.bridgePart[i]) })
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment,
                    self.bridgePart[i].seq,
                    self.bridgePart[i].segment +
                      self.bridgePart[i].seq.toString(),
                    true,
                    false
                  )
                })
              if (this.isIndept) {
                clickParams.sectorID = self.bridgePart[i].segment
                clickParams.sectorNo = self.bridgePart[i].seq
                clickParams.componentID = self.bridgePart[i].segment
                clickParams.componentNo = self.bridgePart[i].seq
                clickParams.text =
                  self.bridgePart[i].segment + self.bridgePart[i].seq.toString()
                clickParams.back = true
                clickParams.action = false
                self.drawCheckMark(
                  g,
                  this.bridgePart[i].isCompleted,
                  (start + end - 20) / 2,
                  230,
                  20,
                  clickParams,
                  `${this.bridgePart[i].segment}${this.bridgePart[i].seq}`
                )
                clickParams = {}
              }
            }
          }
          if (this.bridgeView === 'sideView') {
            if (
              this.bridgeType === 'Box Culvert' ||
              this.bridgeType === 'Box Underpass'
            ) {
              if (
                this.bridgePart[i].segment === 'WW' &&
                this.bridgePart[i].seq === 1
              ) {
                if (this.isIndept) {
                  styleColor =
                    'fill:rgb(255,255,255);stroke-width:3;stroke:rgb(0,0,0)'
                  for (
                    let x = 0;
                    x < this.indeptSectorComplete.WW.items.length;
                    x++
                  ) {
                    if (this.indeptSectorComplete.WW.items[x] === 'WW1') {
                      styleColor = selectColor
                    }
                  }
                } else {
                  styleColor =
                    'fill:rgb(255,255,255);stroke-width:3;stroke:rgb(0,0,0)'
                }
                // ww1-2
                checkallow = await this.checkAllow('ww')
                g.append('polygon')
                  .attr('id', function () {
                    return 'WW1-2'
                  })
                  //                        ซ้าย ล่าง    บน ขวา       ล่าง ขวา
                  .attr('points', `${start - 50},220 ${end},150 ${end},220 `)
                  .on('mouseover', self.mouseovered(true, 'WW1-2', checkallow))
                  .on('mouseout', self.mouseovered(false, 'WW1-2', checkallow))
                  .attr('style', styleColor)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'A',
                          1,
                          'ww',
                          '1-2',
                          'WW1-2',
                          false,
                          true
                        )
                        self.changeColor('WW1-2')
                      }
                      : null
                  )
                g.append('text')
                  .text('WW1-2')
                  .attr('x', start - 4)
                  .attr('y', 212)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on('mouseover', self.mouseovered(true, 'WW1-2', checkallow))
                  .on('mouseout', self.mouseovered(false, 'WW1-2', checkallow))
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'A',
                          1,
                          'ww',
                          '1-2',
                          'WW1-2',
                          false,
                          true
                        )
                        self.changeColor('WW1-2')
                      }
                      : null
                  )
                // ww1-1
                g.append('polygon')
                  .attr('id', function () {
                    return 'WW1-1'
                  })
                  .attr(
                    'points',
                    start.toString() +
                      ',270 ' +
                      end.toString() +
                      ',150 ' +
                      end.toString() +
                      ',270 '
                  )
                  .on('mouseover', self.mouseovered(true, 'WW1-1', checkallow))
                  .on('mouseout', self.mouseovered(false, 'WW1-1', checkallow))
                  .attr('style', styleColor)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'A',
                          1,
                          'ww',
                          '1-1',
                          'WW1-1',
                          false,
                          true
                        )
                        self.changeColor('WW1-1')
                      }
                      : null
                  )
                g.append('text')
                  .text('WW1-1')
                  .attr('x', (start + end) / 2 - 4)
                  .attr('y', 240)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on('mouseover', self.mouseovered(true, 'WW1-1', checkallow))
                  .on('mouseout', self.mouseovered(false, 'WW1-1', checkallow))
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'A',
                          1,
                          'ww',
                          '1-1',
                          'WW1-1',
                          false,
                          true
                        )
                        self.changeColor('WW1-1')
                      }
                      : null
                  )
                start += 20
                end += 40
                g.append('rect')
                  .attr('x', 400)
                  .attr('y', 150)
                  .attr('height', 120)
                  .attr('width', 120 * (this.bridgePart.length - 3))
              } else if (this.bridgePart[i].segment === 'S') {
                if (this.bridgePart[i].seq.toString() !== '1') {
                  start = start + 20
                  end = end + 20
                }
                if (this.isIndept) {
                  styleColor =
                    'fill:rgb(255,255,255);stroke-width:3;stroke:rgb(0,0,0)'
                  for (
                    let x = 0;
                    x < this.indeptSectorComplete.ST.items.length;
                    x++
                  ) {
                    if (
                      this.indeptSectorComplete.ST.items[x] ===
                      'S' + self.bridgePart[i].seq.toString() + '-T'
                    ) {
                      styleColor = selectColor
                    }
                  }
                } else {
                  styleColor =
                    'fill:rgb(255,255,255);stroke-width:3;stroke:rgb(0,0,0)'
                }
                checkallow = await this.checkAllow('ST')
                g.append('rect')
                  .attr('id', function () {
                    return 'S' + self.bridgePart[i].seq + '-T'
                  })
                  .on(
                    'mouseover',
                    self.mouseovered(
                      true,
                      'S' + self.bridgePart[i].seq + '-T',
                      checkallow
                    )
                  )
                  .on(
                    'mouseout',
                    self.mouseovered(
                      false,
                      'S' + self.bridgePart[i].seq + '-T',
                      checkallow
                    )
                  )
                  .attr('x', start)
                  .attr('y', 150)
                  .attr('height', 20)
                  .attr('width', 80)
                  .attr('style', styleColor)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'S',
                          self.bridgePart[i].seq,
                          'ST',
                          1,
                          'S' + self.bridgePart[i].seq + '-T',
                          false,
                          true
                        )
                        self.changeColor('S' + self.bridgePart[i].seq + '-T')
                      }
                      : null
                  )
                g.append('text')
                  .text('S' + self.bridgePart[i].seq + '-T')
                  .on(
                    'mouseover',
                    self.mouseovered(
                      true,
                      'S' + self.bridgePart[i].seq + '-T',
                      checkallow
                    )
                  )
                  .on(
                    'mouseout',
                    self.mouseovered(
                      false,
                      'S' + self.bridgePart[i].seq + '-T',
                      checkallow
                    )
                  )
                  .attr('x', start + 25)
                  .attr('y', 165)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'S',
                          self.bridgePart[i].seq,
                          'ST',
                          1,
                          'S' + self.bridgePart[i].seq + '-T',
                          false,
                          true
                        )
                        self.changeColor('S' + self.bridgePart[i].seq + '-T')
                      }
                      : null
                  )
                if (this.isIndept) {
                  styleColor =
                    'fill:rgb(255,255,255);stroke-width:3;stroke:rgb(0,0,0)'
                  for (
                    let x = 0;
                    x < this.indeptSectorComplete.SB.items.length;
                    x++
                  ) {
                    if (
                      this.indeptSectorComplete.SB.items[x] ===
                      'S' + self.bridgePart[i].seq.toString() + '-B'
                    ) {
                      styleColor = selectColor
                    }
                  }
                } else {
                  styleColor =
                    'fill:rgb(255,255,255);stroke-width:3;stroke:rgb(0,0,0)'
                }
                checkallow = await this.checkAllow('SB')
                g.append('rect')
                  .attr('id', function () {
                    return 'S' + self.bridgePart[i].seq + '-B'
                  })
                  .on(
                    'mouseover',
                    self.mouseovered(
                      true,
                      'S' + self.bridgePart[i].seq + '-B',
                      checkallow
                    )
                  )
                  .on(
                    'mouseout',
                    self.mouseovered(
                      false,
                      'S' + self.bridgePart[i].seq + '-B',
                      checkallow
                    )
                  )
                  .attr('x', start)
                  .attr('y', 250)
                  .attr('height', 20)
                  .attr('width', 80)
                  .attr('style', styleColor)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'S',
                          self.bridgePart[i].seq,
                          'B',
                          null,
                          'S' + self.bridgePart[i].seq + '-B',
                          false,
                          true
                        )
                      }
                      : null
                  )
                  .on('click', function () {
                    self.bridgeComponentClickObject(
                      'S',
                      self.bridgePart[i].seq,
                      'SB',
                      1,
                      'S' + self.bridgePart[i].seq + '-B',
                      false,
                      true,
                      checkallow
                    )
                    self.changeColor('S' + self.bridgePart[i].seq + '-B', 'SB')
                  })
                g.append('text')
                  .text('S' + self.bridgePart[i].seq + '-B')
                  .on(
                    'mouseover',
                    self.mouseovered(
                      true,
                      'S' + self.bridgePart[i].seq + '-B',
                      checkallow
                    )
                  )
                  .on(
                    'mouseout',
                    self.mouseovered(
                      false,
                      'S' + self.bridgePart[i].seq + '-B',
                      checkallow
                    )
                  )
                  .attr('x', start + 25)
                  .attr('y', 265)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'S',
                          self.bridgePart[i].seq,
                          'SB',
                          1,
                          'S' + self.bridgePart[i].seq + '-B',
                          false,
                          true
                        )
                        self.changeColor('S' + self.bridgePart[i].seq + '-B')
                      }
                      : null
                  )
                if (this.isIndept) {
                  styleColor =
                    'fill:rgb(255,255,255);stroke-width:3;stroke:rgb(0,0,0)'
                  for (
                    let x = 0;
                    x < this.indeptSectorComplete.CV.items.length;
                    x++
                  ) {
                    if (
                      this.indeptSectorComplete.CV.items[x] ===
                      `CV${self.bridgePart[i].seq}-1`
                    ) {
                      styleColor = selectColor
                    }
                  }
                } else {
                  styleColor =
                    'fill:rgb(255,255,255);stroke-width:3;stroke:rgb(0,0,0)'
                }
                checkallow = await this.checkAllow('CV')
                g.append('rect')
                  .attr('id', function () {
                    return `CV${self.bridgePart[i].seq}-1`
                  })
                  .on(
                    'mouseover',
                    self.mouseovered(
                      true,
                      `CV${self.bridgePart[i].seq}-1`,
                      checkallow
                    )
                  )
                  .on(
                    'mouseout',
                    self.mouseovered(
                      false,
                      `CV${self.bridgePart[i].seq}-1`,
                      checkallow
                    )
                  )
                  .attr('x', start - 20)
                  .attr('y', 150)
                  .attr('height', 120)
                  .attr('width', 20)
                  .attr('style', styleColor)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on('click', function () {
                    self.bridgeComponentClickObject(
                      'CV',
                      self.bridgePart[i].seq,
                      null,
                      1,
                      `CV${self.bridgePart[i].seq}-1`,
                      false,
                      true
                    )
                  })
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'S',
                          self.bridgePart[i].seq,
                          'CV',
                          1,
                            `CV${self.bridgePart[i].seq}-1`,
                            false,
                            true
                        )
                        self.changeColor(`CV${self.bridgePart[i].seq}-1`)
                      }
                      : null
                  )
                g.append('text')
                  .text(`CV${self.bridgePart[i].seq}-1`)
                  .on(
                    'mouseover',
                    self.mouseovered(
                      true,
                      `CV${self.bridgePart[i].seq}-1`,
                      checkallow
                    )
                  )
                  .on(
                    'mouseout',
                    self.mouseovered(
                      false,
                      `CV${self.bridgePart[i].seq}-1`,
                      checkallow
                    )
                  )
                  .attr('x', start - 15)
                  .attr('y', 290)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'S',
                          self.bridgePart[i].seq,
                          'CV',
                          1,
                            `CV${self.bridgePart[i].seq}-1`,
                            false,
                            true
                        )
                        self.changeColor(`CV${self.bridgePart[i].seq}-1`)
                      }
                      : null
                  )
                if (this.isIndept) {
                  styleColor =
                    'fill:rgb(255,255,255);stroke-width:3;stroke:rgb(0,0,0)'
                  for (
                    let x = 0;
                    x < this.indeptSectorComplete.CV.items.length;
                    x++
                  ) {
                    if (
                      this.indeptSectorComplete.CV.items[x] ===
                      `CV${self.bridgePart[i].seq}-2`
                    ) {
                      styleColor = selectColor
                    }
                  }
                } else {
                  styleColor =
                    'fill:rgb(255,255,255);stroke-width:3;stroke:rgb(0,0,0)'
                }
                g.append('rect')
                  .attr('id', function () {
                    return `CV${self.bridgePart[i].seq}-2`
                  })
                  .on(
                    'mouseover',
                    self.mouseovered(
                      true,
                      `CV${self.bridgePart[i].seq}-2`,
                      checkallow
                    )
                  )
                  .on(
                    'mouseout',
                    self.mouseovered(
                      false,
                      `CV${self.bridgePart[i].seq}-2`,
                      checkallow
                    )
                  )
                  .attr('x', start + 80)
                  .attr('y', 150)
                  .attr('height', 120)
                  .attr('width', 20)
                  .attr('style', styleColor)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'S',
                          self.bridgePart[i].seq,
                          'CV',
                          2,
                            `CV${self.bridgePart[i].seq}-2`,
                            false,
                            true
                        )
                        self.changeColor(`CV${self.bridgePart[i].seq}-2`)
                      }
                      : null
                  )
                g.append('text')
                  .text(`CV${self.bridgePart[i].seq}-2`)
                  .on(
                    'mouseover',
                    self.mouseovered(
                      true,
                      `CV${self.bridgePart[i].seq}-2`,
                      checkallow
                    )
                  )
                  .on(
                    'mouseout',
                    self.mouseovered(
                      false,
                      `CV${self.bridgePart[i].seq}-2`,
                      checkallow
                    )
                  )
                  .attr('x', start + 60)
                  .attr('y', 140)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'S',
                          self.bridgePart[i].seq,
                          'CV',
                          2,
                            `CV${self.bridgePart[i].seq}-2`,
                            false,
                            true
                        )
                        self.changeColor(`CV${self.bridgePart[i].seq}-2`)
                      }
                      : null
                  )
                g.append('rect')
                  .attr('x', start)
                  .attr('y', 170)
                  .attr('height', 80)
                  .attr('width', 80)
                  .attr(
                    'style',
                    'fill:rgb(255,255,255);stroke-width:3;stroke:rgb(0,0,0)'
                  )
                g.append('text')
                  .text('CV' + i.toString())
                  .attr('x', start + 25)
                  .attr('y', 215)
              } else if (
                this.bridgePart[i].segment === 'WW' &&
                this.bridgePart[i].seq === 2
              ) {
                end -= 20
                if (this.isIndept) {
                  styleColor =
                    'fill:rgb(255,255,255);stroke-width:3;stroke:rgb(0,0,0)'
                  for (
                    let x = 0;
                    x < this.indeptSectorComplete.WW.items.length;
                    x++
                  ) {
                    if (this.indeptSectorComplete.WW.items[x] === 'WW2') {
                      styleColor = selectColor
                    }
                  }
                } else {
                  styleColor =
                    'fill:rgb(255,255,255);stroke-width:3;stroke:rgb(0,0,0)'
                }
                // ww2-2
                checkallow = await this.checkAllow('ww')
                g.append('polygon')
                  .attr('id', function () {
                    return 'WW2-2'
                  })
                  //                    ล่างซ้าย           บน ซ้าย       ล่าง ขวา
                  .attr('points', `${start},220 ${start},150 ${end + 50},220 `)
                  .on('mouseover', self.mouseovered(true, 'WW2-2', checkallow))
                  .on('mouseout', self.mouseovered(false, 'WW2-2', checkallow))
                  .attr('style', styleColor)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'A',
                          1,
                          'ww',
                          '2-2',
                          'WW2-2',
                          false,
                          true
                        )
                        self.changeColor('WW2-2')
                      }
                      : null
                  )
                g.append('text')
                  .text('WW2-2')
                  .attr('x', end - 47)
                  .attr('y', 212)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on('mouseover', self.mouseovered(true, 'WW2-2', checkallow))
                  .on('mouseout', self.mouseovered(false, 'WW2-2', checkallow))
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'A',
                          1,
                          'ww',
                          '2-2',
                          'WW2-2',
                          false,
                          true
                        )
                        self.changeColor('WW2-2')
                      }
                      : null
                  )
                // ww2-1
                g.append('polygon')
                  .attr('id', function () {
                    return 'WW2-1'
                  })
                  .on('mouseover', self.mouseovered(true, 'WW2-1', checkallow))
                  .on('mouseout', self.mouseovered(false, 'WW2-1', checkallow))
                  .attr(
                    'points',
                    start.toString() +
                      ',270 ' +
                      start.toString() +
                      ',150 ' +
                      end.toString() +
                      ',270 '
                  )
                  .attr('style', styleColor)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'A',
                          2,
                          'ww',
                          '2-1',
                          'WW2-1',
                          false,
                          true
                        )
                        self.changeColor('WW2-1')
                      }
                      : null
                  )
                g.append('text')
                  .text('WW2-1')
                  .on('mouseover', self.mouseovered(true, 'WW2-1', checkallow))
                  .on('mouseout', self.mouseovered(false, 'WW2-1', checkallow))
                  .attr('x', (start + end - 80) / 2)
                  .attr('y', 240)
                  .attr('class', checkallow ? 'hoverItem' : null)
                  .on(
                    'click',
                    checkallow
                      ? function () {
                        self.bridgeComponentClickObject(
                          'A',
                          2,
                          'ww',
                          '2-1',
                          'WW2-1',
                          false,
                          true
                        )
                        self.changeColor('WW2-1')
                      }
                      : null
                  )
              }
              start -= 100
              end -= 100
            } else {
              if (this.bridgePart[i].segment === 'S') {
                g.append('rect')
                  .attr('x', start)
                  .attr('y', 150)
                  .attr('height', 50)
                  .attr('width', 200)
                  .attr(
                    'style',
                    'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)'
                  )
                for (let j = 0; j < 2; j++) {
                  for (let k = 0; k < 4; k++) {
                    g.append('rect')
                      .attr('x', start + 10 + 48 * k)
                      .attr('y', 160 + 20 * j)
                      .attr('height', 10)
                      .attr('width', 40)
                      .attr(
                        'style',
                        'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)'
                      )
                  }
                }
                g.append('rect')
                  .attr('x', start)
                  .attr('y', 200)
                  .attr('height', 50)
                  .attr('width', 200)
                  .attr(
                    'style',
                    'fill:rgb(226,226,226);stroke-width:1;stroke:rgb(0,0,0)'
                  )
                g.append('text')
                  .text(
                    this.bridgePart[i].segment +
                      this.bridgePart[i].seq.toString() +
                      ' บน'
                  )
                  .attr('x', start + 90)
                  .attr('y', 130)
                  .attr('class', 'textLayer')
                g.append('text')
                  .text(
                    this.bridgePart[i].segment +
                      this.bridgePart[i].seq.toString() +
                      ' ล่าง'
                  )
                  .attr('x', start + 90)
                  .attr('y', 230)
                  .attr('class', 'textLayer')
              }
            }
          }
          start += 100
          end += 100
          if (this.bridgeView === 'sideView') {
            start += 100
            end += 100
          }
        }
        if (this.selectedPart !== false && this.selectedPart) {
          const splitPart = this.selectedPart.split('-')
          if (splitPart[1] === 'EJ1') {
            this.changeColor(this.selectedPart)
          }
          // splitPart = splitPart.slice(1)
        }
        if (this.bridgeView === 'sideView') {
          let end = 400
          let BCBUFlag = true
          if (
            this.bridgeType === 'Box Culvert' ||
            this.bridgeType === 'Box Underpass'
          ) {
            BCBUFlag = false
          }
          if (BCBUFlag) {
            for (let i = 0; i < this.bridgePart.length; i++) {
              if (
                this.bridgePart[i].segment === 'A' &&
                this.bridgePart[i].seq === 1
              ) {
                g.append('svg:image')
                  .attr('height', 200)
                  .attr('x', 406)
                  .attr('y', 197)
                  .attr('xlink:href', function () {
                    return self.imgPath.sideALeft
                  })
                g.append('text')
                  .text('A1')
                  .attr('x', 410)
                  .attr('y', 300)
                  .attr('class', 'textLayer')
              } else if (
                this.bridgePart[i].segment === 'A' &&
                this.bridgePart[i].seq === 2
              ) {
                g.append('svg:image')
                  .attr('height', 200)
                  .attr('x', end - 210)
                  .attr('y', 197)
                  .attr('xlink:href', function () {
                    return self.imgPath.sideARight
                  })
                g.append('text')
                  .text('A2')
                  .attr('x', end - 250)
                  .attr('y', 300)
                  .attr('class', 'textLayer')
              } else {
                if (i < this.bridgePart.length - 2) {
                  g.append('svg:image')
                    .attr('height', 205)
                    .attr('x', end + 81)
                    .attr('y', 196)
                    .attr('xlink:href', function () {
                      return self.imgPath.sideP
                    })
                } else {
                  if (this.bridgePart[i + 1]) {
                    if (
                      !(
                        this.bridgePart[i + 1].segment === 'A' &&
                        this.bridgePart[i + 1].seq === 2
                      )
                    ) {
                      g.append('svg:image')
                        .attr('height', 205)
                        .attr('x', end + 81)
                        .attr('y', 196)
                        .attr('xlink:href', function () {
                          return self.imgPath.sideP
                        })
                    }
                  }
                }
              }
              end += 200
            }
          }
        }
        if (this.accidentMode) {
          const all = document.getElementsByClassName('hoverItem')
          for (let i = 0; i < all.length; i++) {
            all[i].style.cursor = 'auto'
          }
          if (this.accidentType === 'overview') {
            if (this.bridgeView === 'topView') {
              for (let i = 1; i <= 9; i++) {
                if (this.bridgeDamageImagePath[i - 1] === null) {
                  const imgItem = g
                    .append('svg:defs')
                    .append('svg:pattern')
                    .attr('id', 'circle-image-' + i)
                    .attr('x', 0)
                    .attr('y', 0)
                    // .attr('patternUnits', 'userSpaceOnUse')
                    .attr('width', 50)
                    .attr('height', 50)

                  imgItem
                    .append('image')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 40)
                    .attr('height', 40)
                    .attr('xlink:href', function () {
                      return self.imgPath.defaultDamageImage
                    })

                  imgItem.append('text').text(i).attr('x', 15).attr('y', 28)
                } else {
                  const imgItem = g
                    .append('svg:defs')
                    .append('svg:pattern')
                    .attr('id', 'circle-image-' + i)
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 50)
                    .attr('height', 50)

                  imgItem
                    .append('image')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 40)
                    .attr('height', 40)
                    .attr('xlink:href', function () {
                      return self.bridgeDamageImagePath[i - 1]
                    })
                }
              }
              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr('x1', 300)
                .attr('y1', 150)
                .attr('x2', 350)
                .attr('y2', 155)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 365)
                .attr('y', 140)
                .attr('width', 40)
                .attr('height', 40)
                .attr('rx', 5)
                .attr('fill', 'url(#circle-image-1)')
                .attr('transform', 'rotate(90 385 155)')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(1)
                })
              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr('x1', 280)
                .attr('y1', 275)
                .attr('x2', 350)
                .attr('y2', 275)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 485)
                .attr('y', 140)
                .attr('width', 40)
                .attr('height', 40)
                .attr('rx', 5)
                .attr('fill', 'url(#circle-image-2)')
                .attr('transform', 'rotate(90 385 155)')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(2)
                })
              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr('x1', 300)
                .attr('y1', 400)
                .attr('x2', 350)
                .attr('y2', 395)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 600)
                .attr('y', 140)
                .attr('width', 40)
                .attr('height', 40)
                .attr('rx', 5)
                .attr('fill', 'url(#circle-image-3)')
                .attr('transform', 'rotate(90 385 155)')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(3)
                })

              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr('x1', start / 2 + 150)
                .attr('y1', 200)
                .attr('x2', start / 2 + 200)
                .attr('y2', 210)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 420)
                .attr('y', 0 - start / 2 + 290)
                .attr('width', 40)
                .attr('height', 40)
                .attr('rx', 5)
                .attr('fill', 'url(#circle-image-4)')
                .attr('transform', 'rotate(90 385 155)')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(4)
                })
              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr('x1', start / 2 + 150)
                .attr('y1', 275)
                .attr('x2', start / 2 + 200)
                .attr('y2', 275)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 485)
                .attr('y', 0 - start / 2 + 290)
                .attr('width', 40)
                .attr('height', 40)
                .attr('rx', 5)
                .attr('fill', 'url(#circle-image-5)')
                .attr('transform', 'rotate(90 385 155)')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(5)
                })
              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr('x1', start / 2 + 150)
                .attr('y1', 350)
                .attr('x2', start / 2 + 200)
                .attr('y2', 340)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 550)
                .attr('y', 0 - start / 2 + 290)
                .attr('width', 40)
                .attr('height', 40)
                .attr('rx', 5)
                .attr('fill', 'url(#circle-image-6)')
                .attr('transform', 'rotate(90 385 155)')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(6)
                })

              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr('x1', end - 110)
                .attr('y1', 150)
                .attr('x2', end - 160)
                .attr('y2', 155)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 365)
                .attr('y', 0 - end + 710)
                .attr('width', 40)
                .attr('height', 40)
                .attr('rx', 5)
                .attr('fill', 'url(#circle-image-7)')
                .attr('transform', 'rotate(90 385 155)')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(7)
                })
              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr('x1', end - 90)
                .attr('y1', 275)
                .attr('x2', end - 160)
                .attr('y2', 275)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 485)
                .attr('y', 0 - end + 710)
                .attr('width', 40)
                .attr('height', 40)
                .attr('rx', 5)
                .attr('fill', 'url(#circle-image-8)')
                .attr('transform', 'rotate(90 385 155)')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(8)
                })
              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr('x1', end - 110)
                .attr('y1', 400)
                .attr('x2', end - 160)
                .attr('y2', 395)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 600)
                .attr('y', 0 - end + 710)
                .attr('width', 40)
                .attr('height', 40)
                .attr('rx', 5)
                .attr('fill', 'url(#circle-image-9)')
                .attr('transform', 'rotate(90 385 155)')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(9)
                })
            }
          } else if (this.accidentType === 'detail') {
            if (this.bridgeView === 'topView') {
              for (let i = 1; i <= this.bridgeDamageImagePath.length + 1; i++) {
                if (this.bridgeDamageImagePath[i - 1] === null) {
                  const imgItem = g
                    .append('svg:defs')
                    .append('svg:pattern')
                    .attr('id', 'circle-image-' + i)
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 30)
                    .attr('height', 30)

                  imgItem
                    .append('image')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 30)
                    .attr('height', 30)
                    .attr('xlink:href', function () {
                      return self.imgPath.defaultDamageImage
                    })
                  if (i < 10) {
                    imgItem.append('text').text(i).attr('x', 10).attr('y', 21)
                  } else if (i < 100 && i >= 10) {
                    imgItem.append('text').text(i).attr('x', 7).attr('y', 21)
                  } else {
                    imgItem.append('text').text(i).attr('x', 3).attr('y', 21)
                  }
                } else {
                  const imgItem = g
                    .append('svg:defs')
                    .append('svg:pattern')
                    .attr('id', 'circle-image-' + i)
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 30)
                    .attr('height', 30)

                  imgItem
                    .append('image')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 30)
                    .attr('height', 30)
                    .attr('xlink:href', function () {
                      return self.bridgeDamageImagePath[i - 1]
                    })
                }
              }
              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr('x1', 250)
                .attr('y1', 130)
                .attr('x2', 280)
                .attr('y2', 135)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 350)
                .attr('y', 220)
                .attr('width', 30)
                .attr('height', 30)
                .attr('rx', 5)
                .attr('fill', 'url(#circle-image-1)')
                .attr('transform', 'rotate(90 385 155)')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(1)
                })
              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr('x1', 250)
                .attr('y1', 275)
                .attr('x2', 280)
                .attr('y2', 275)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 490)
                .attr('y', 220)
                .attr('width', 30)
                .attr('height', 30)
                .attr('rx', 5)
                .attr('fill', 'url(#circle-image-2)')
                .attr('transform', 'rotate(90 385 155)')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(2)
                })
              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr('x1', 250)
                .attr('y1', 400)
                .attr('x2', 280)
                .attr('y2', 395)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 610)
                .attr('y', 220)
                .attr('width', 30)
                .attr('height', 30)
                .attr('rx', 5)
                .attr('fill', 'url(#circle-image-3)')
                .attr('transform', 'rotate(90 385 155)')
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(3)
                })
              const damageStart = 80
              for (let i = 0; i < this.bridgePart.length - 2; i++) {
                if (i === 0) {
                  g.append('line')
                    .style('stroke', 'rgb(149,55,53)')
                    .style('stroke-width', 3)
                    .attr('x1', damageStart / 2 + 350 + i * 100)
                    .attr('y1', 200)
                    .attr('x2', damageStart / 2 + 350 + i * 100 - 20)
                    .attr('y2', 205)
                    .attr('marker-start', 'url(#circle-point)')
                    .attr('marker-end', 'url(#arrowhead)')
                  g.append('rect')
                    .attr('x', 420)
                    .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 320)
                    .attr('width', 30)
                    .attr('height', 30)
                    .attr('rx', 5)
                    .attr(
                      'fill',
                      'url(#circle-image-' + ((i + 1) * 3 + 1).toString() + ')'
                    )
                    .attr('transform', 'rotate(90 385 155)')
                    .attr('class', 'hoverItem')
                    .on('click', function () {
                      self.bridgeDamageClickObject((i + 1) * 3 + 1)
                    })
                  g.append('line')
                    .style('stroke', 'rgb(149,55,53)')
                    .style('stroke-width', 3)
                    .attr('x1', damageStart / 2 + 350 + i * 100)
                    .attr('y1', 350)
                    .attr('x2', damageStart / 2 + 350 + i * 100 - 20)
                    .attr('y2', 345)
                    .attr('marker-start', 'url(#circle-point)')
                    .attr('marker-end', 'url(#arrowhead)')
                  g.append('rect')
                    .attr('x', 560)
                    .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 320)
                    .attr('width', 30)
                    .attr('height', 30)
                    .attr('rx', 5)
                    .attr(
                      'fill',
                      'url(#circle-image-' +
                        (
                          (this.bridgePart.length - 2) * 4 +
                          7 +
                          (this.bridgePart.length - 2 - i) * 4 +
                          5
                        ).toString() +
                        ')'
                    )
                    .attr('transform', 'rotate(90 385 155)')
                    .attr('class', 'hoverItem')
                    .on('click', function () {
                      self.bridgeDamageClickObject(
                        (self.bridgePart.length - 2) * 4 +
                          7 +
                          (self.bridgePart.length - 2 - i) * 4 +
                          5
                      )
                    })
                }

                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 200)
                  .attr('x2', damageStart / 2 + 350 + i * 100)
                  .attr('y2', 210)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 450)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 285)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' + ((i + 1) * 4 + 1).toString() + ')'
                  )
                  .attr('transform', 'rotate(90 385 155)')
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject((i + 1) * 4 + 1)
                  })
                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 350)
                  .attr('x2', damageStart / 2 + 350 + i * 100)
                  .attr('y2', 340)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 450 + 30 + 50)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 285)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' +
                      (
                        (this.bridgePart.length - 2) * 4 +
                        7 +
                        (this.bridgePart.length - 2 - i) * 4 +
                        4
                      ).toString() +
                      ')'
                  )
                  .attr('transform', 'rotate(90 385 155)')
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject(
                      (self.bridgePart.length - 2) * 4 +
                        7 +
                        (self.bridgePart.length - 2 - i) * 4 +
                        4
                    )
                  })
                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 200)
                  .attr('x2', damageStart / 2 + 350 + i * 100 + 15)
                  .attr('y2', 220)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 460)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 255)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' + ((i + 1) * 4 + 2).toString() + ')'
                  )
                  .attr('transform', 'rotate(90 385 155)')
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject((i + 1) * 4 + 2)
                  })
                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 350)
                  .attr('x2', damageStart / 2 + 350 + i * 100 + 15)
                  .attr('y2', 330)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 440 + 30 + 50)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 255)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' +
                      (
                        (this.bridgePart.length - 2) * 4 +
                        7 +
                        (this.bridgePart.length - 2 - i) * 4 +
                        3
                      ).toString() +
                      ')'
                  )
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject(
                      (self.bridgePart.length - 2) * 4 +
                        7 +
                        (self.bridgePart.length - 2 - i) * 4 +
                        3
                    )
                  })
                  .attr('transform', 'rotate(90 385 155)')
                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 200)
                  .attr('x2', damageStart / 2 + 350 + i * 100 + 25)
                  .attr('y2', 220)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 445)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 225)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' + ((i + 1) * 4 + 3).toString() + ')'
                  )
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject((i + 1) * 4 + 3)
                  })
                  .attr('transform', 'rotate(90 385 155)')
                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 350)
                  .attr('x2', damageStart / 2 + 350 + i * 100 + 25)
                  .attr('y2', 330)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 455 + 30 + 50)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 225)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' +
                      (
                        (this.bridgePart.length - 2) * 4 +
                        7 +
                        (this.bridgePart.length - 2 - i) * 4 +
                        2
                      ).toString() +
                      ')'
                  )
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject(
                      (self.bridgePart.length - 2) * 4 +
                        7 +
                        (self.bridgePart.length - 2 - i) * 4 +
                        2
                    )
                  })
                  .attr('transform', 'rotate(90 385 155)')
                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 200)
                  .attr('x2', damageStart / 2 + 350 + i * 100 + 20)
                  .attr('y2', 200)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 415)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 230)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' + ((i + 1) * 4 + 4).toString() + ')'
                  )
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject((i + 1) * 4 + 4)
                  })
                  .attr('transform', 'rotate(90 385 155)')
                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 350)
                  .attr('x2', damageStart / 2 + 350 + i * 100 + 20)
                  .attr('y2', 350)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 485 + 30 + 50)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 230)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' +
                      (
                        (this.bridgePart.length - 2) * 4 +
                        7 +
                        (this.bridgePart.length - 2 - i) * 4 +
                        1
                      ).toString() +
                      ')'
                  )
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject(
                      (self.bridgePart.length - 2) * 4 +
                        7 +
                        (self.bridgePart.length - 2 - i) * 4 +
                        1
                    )
                  })
                  .attr('transform', 'rotate(90 385 155)')
              }

              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr(
                  'x1',
                  damageStart / 2 + 350 + (this.bridgePart.length - 2) * 100
                )
                .attr('y1', 200)
                .attr(
                  'x2',
                  damageStart / 2 + 350 + (this.bridgePart.length - 2) * 100
                )
                .attr('y2', 210)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 450)
                .attr(
                  'y',
                  0 -
                    (damageStart / 2 + 100) -
                    (this.bridgePart.length - 2) * 100 +
                    275
                )
                .attr('width', 30)
                .attr('height', 30)
                .attr('rx', 5)
                .attr(
                  'fill',
                  'url(#circle-image-' +
                    ((this.bridgePart.length - 2) * 4 + 4 + 1).toString() +
                    ')'
                )
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(
                    (self.bridgePart.length - 2) * 4 + 4 + 1
                  )
                })
                .attr('transform', 'rotate(90 385 155)')
              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr(
                  'x1',
                  damageStart / 2 + 350 + (this.bridgePart.length - 2) * 100
                )
                .attr('y1', 350)
                .attr(
                  'x2',
                  damageStart / 2 + 350 + (this.bridgePart.length - 2) * 100
                )
                .attr('y2', 340)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 450 + 30 + 50)
                .attr(
                  'y',
                  0 -
                    (damageStart / 2 + 100) -
                    (this.bridgePart.length - 2) * 100 +
                    275
                )
                .attr('width', 30)
                .attr('height', 30)
                .attr('rx', 5)
                .attr(
                  'fill',
                  'url(#circle-image-' +
                    ((this.bridgePart.length - 2) * 4 + 4 + 7).toString() +
                    ')'
                )
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(
                    (self.bridgePart.length - 2) * 4 + 4 + 7
                  )
                })
                .attr('transform', 'rotate(90 385 155)')

              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr(
                  'x1',
                  damageStart / 2 + 350 + (this.bridgePart.length - 2) * 100
                )
                .attr('y1', 200)
                .attr(
                  'x2',
                  damageStart / 2 +
                    350 +
                    (this.bridgePart.length - 2) * 100 +
                    20
                )
                .attr('y2', 205)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 420)
                .attr(
                  'y',
                  0 -
                    (damageStart / 2 + 100) -
                    (this.bridgePart.length - 2) * 100 +
                    230
                )
                .attr('width', 30)
                .attr('height', 30)
                .attr('rx', 5)
                .attr(
                  'fill',
                  'url(#circle-image-' +
                    ((this.bridgePart.length - 2) * 4 + 4 + 2).toString() +
                    ')'
                )
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(
                    (self.bridgePart.length - 2) * 4 + 4 + 2
                  )
                })
                .attr('transform', 'rotate(90 385 155)')
              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr(
                  'x1',
                  damageStart / 2 + 350 + (this.bridgePart.length - 2) * 100
                )
                .attr('y1', 350)
                .attr(
                  'x2',
                  damageStart / 2 +
                    350 +
                    (this.bridgePart.length - 2) * 100 +
                    20
                )
                .attr('y2', 345)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 475 + 30 + 50)
                .attr(
                  'y',
                  0 -
                    (damageStart / 2 + 100) -
                    (this.bridgePart.length - 2) * 100 +
                    230
                )
                .attr('width', 30)
                .attr('height', 30)
                .attr('rx', 5)
                .attr(
                  'fill',
                  'url(#circle-image-' +
                    ((this.bridgePart.length - 2) * 4 + 4 + 6).toString() +
                    ')'
                )
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(
                    (self.bridgePart.length - 2) * 4 + 4 + 6
                  )
                })
                .attr('transform', 'rotate(90 385 155)')

              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr(
                  'x1',
                  damageStart / 2 +
                    350 +
                    (this.bridgePart.length - 2) * 100 +
                    140
                )
                .attr('y1', 130)
                .attr(
                  'x2',
                  damageStart / 2 +
                    350 +
                    (this.bridgePart.length - 2) * 100 +
                    110
                )
                .attr('y2', 135)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 350)
                .attr(
                  'y',
                  0 -
                    (damageStart / 2 + 100) -
                    (this.bridgePart.length - 2) * 100 +
                    190
                )
                .attr('width', 30)
                .attr('height', 30)
                .attr('rx', 5)
                .attr(
                  'fill',
                  'url(#circle-image-' +
                    ((this.bridgePart.length - 2) * 4 + 4 + 3).toString() +
                    ')'
                )
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(
                    (self.bridgePart.length - 2) * 4 + 4 + 3
                  )
                })
                .attr('transform', 'rotate(90 385 155)')
              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr(
                  'x1',
                  damageStart / 2 +
                    350 +
                    (this.bridgePart.length - 2) * 100 +
                    140
                )
                .attr('y1', 275)
                .attr(
                  'x2',
                  damageStart / 2 +
                    350 +
                    (this.bridgePart.length - 2) * 100 +
                    110
                )
                .attr('y2', 275)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 490)
                .attr(
                  'y',
                  0 -
                    (damageStart / 2 + 100) -
                    (this.bridgePart.length - 2) * 100 +
                    190
                )
                .attr('width', 30)
                .attr('height', 30)
                .attr('rx', 5)
                .attr(
                  'fill',
                  'url(#circle-image-' +
                    ((this.bridgePart.length - 2) * 4 + 4 + 4).toString() +
                    ')'
                )
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(
                    (self.bridgePart.length - 2) * 4 + 4 + 4
                  )
                })
                .attr('transform', 'rotate(90 385 155)')
              g.append('line')
                .style('stroke', 'rgb(149,55,53)')
                .style('stroke-width', 3)
                .attr(
                  'x1',
                  damageStart / 2 +
                    350 +
                    (this.bridgePart.length - 2) * 100 +
                    140
                )
                .attr('y1', 400)
                .attr(
                  'x2',
                  damageStart / 2 +
                    350 +
                    (this.bridgePart.length - 2) * 100 +
                    110
                )
                .attr('y2', 395)
                .attr('marker-start', 'url(#circle-point)')
                .attr('marker-end', 'url(#arrowhead)')
              g.append('rect')
                .attr('x', 610)
                .attr(
                  'y',
                  0 -
                    (damageStart / 2 + 100) -
                    (this.bridgePart.length - 2) * 100 +
                    190
                )
                .attr('width', 30)
                .attr('height', 30)
                .attr('rx', 5)
                .attr(
                  'fill',
                  'url(#circle-image-' +
                    ((this.bridgePart.length - 2) * 4 + 4 + 5).toString() +
                    ')'
                )
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeDamageClickObject(
                    (self.bridgePart.length - 2) * 4 + 4 + 5
                  )
                })
                .attr('transform', 'rotate(90 385 155)')
            } else if (this.bridgeView === 'bottomView') {
              for (let i = 1; i <= this.bridgeDamageImagePath.length + 1; i++) {
                if (this.bridgeDamageImagePath[i - 1] === null) {
                  const imgItem = g
                    .append('svg:defs')
                    .append('svg:pattern')
                    .attr('id', 'circle-image-' + i)
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 30)
                    .attr('height', 30)

                  imgItem
                    .append('image')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 30)
                    .attr('height', 30)
                    .attr('xlink:href', function () {
                      return self.imgPath.defaultDamageImage
                    })
                  if (i < 10) {
                    imgItem.append('text').text(i).attr('x', 10).attr('y', 21)
                  } else if (i < 100 && i >= 10) {
                    imgItem.append('text').text(i).attr('x', 7).attr('y', 21)
                  } else {
                    imgItem.append('text').text(i).attr('x', 3).attr('y', 21)
                  }
                } else {
                  const imgItem = g
                    .append('svg:defs')
                    .append('svg:pattern')
                    .attr('id', 'circle-image-' + i)
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 30)
                    .attr('height', 30)

                  imgItem
                    .append('image')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 30)
                    .attr('height', 30)
                    .attr('xlink:href', function () {
                      return self.bridgeDamageImagePath[i - 1]
                    })
                }
              }
              const damageStart = 200
              for (let i = 0; i < this.bridgePart.length - 2; i++) {
                if (i === 0) {
                  g.append('line')
                    .style('stroke', 'rgb(149,55,53)')
                    .style('stroke-width', 3)
                    .attr('x1', damageStart / 2 + 350 + i * 100)
                    .attr('y1', 100)
                    .attr('x2', damageStart / 2 + 350 + i * 100 - 50)
                    .attr('y2', 110)
                    .attr('marker-start', 'url(#circle-point)')
                    .attr('marker-end', 'url(#arrowhead)')
                  g.append('rect')
                    .attr('x', 320)
                    .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 350)
                    .attr('width', 30)
                    .attr('height', 30)
                    .attr('rx', 5)
                    .attr('fill', 'url(#circle-image-' + (1).toString() + ')')
                    .attr('class', 'hoverItem')
                    .on('click', function () {
                      self.bridgeDamageClickObject(1)
                    })
                    .attr('transform', 'rotate(90 385 155)')
                  g.append('line')
                    .style('stroke', 'rgb(149,55,53)')
                    .style('stroke-width', 3)
                    .attr('x1', damageStart / 2 + 350 + i * 100)
                    .attr('y1', 450)
                    .attr('x2', damageStart / 2 + 350 + i * 100 - 50)
                    .attr('y2', 440)
                    .attr('marker-start', 'url(#circle-point)')
                    .attr('marker-end', 'url(#arrowhead)')
                  g.append('rect')
                    .attr('x', 660)
                    .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 350)
                    .attr('width', 30)
                    .attr('height', 30)
                    .attr('rx', 5)
                    .attr(
                      'fill',
                      'url(#circle-image-' +
                        (
                          3 +
                          (this.bridgePart.length - 2) * 4 +
                          (this.bridgePart.length - 3) * 4 +
                          5
                        ).toString() +
                        ')'
                    )
                    .attr('class', 'hoverItem')
                    .on('click', function () {
                      self.bridgeDamageClickObject(
                        3 +
                          (self.bridgePart.length - 2) * 4 +
                          (self.bridgePart.length - 3) * 4 +
                          5
                      )
                    })
                    .attr('transform', 'rotate(90 385 155)')
                }
                if (i === this.bridgePart.length - 3) {
                  g.append('line')
                    .style('stroke', 'rgb(149,55,53)')
                    .style('stroke-width', 3)
                    .attr('x1', damageStart / 2 + 350 + i * 100)
                    .attr('y1', 100)
                    .attr('x2', damageStart / 2 + 350 + i * 100 + 50)
                    .attr('y2', 105)
                    .attr('marker-start', 'url(#circle-point)')
                    .attr('marker-end', 'url(#arrowhead)')
                  g.append('rect')
                    .attr('x', 315)
                    .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 200)
                    .attr('width', 30)
                    .attr('height', 30)
                    .attr('rx', 5)
                    .attr(
                      'fill',
                      'url(#circle-image-' + (i * 4 + 6).toString() + ')'
                    )
                    .attr('class', 'hoverItem')
                    .on('click', function () {
                      self.bridgeDamageClickObject(i * 4 + 6)
                    })
                    .attr('transform', 'rotate(90 385 155)')
                  g.append('line')
                    .style('stroke', 'rgb(149,55,53)')
                    .style('stroke-width', 3)
                    .attr('x1', damageStart / 2 + 350 + i * 100)
                    .attr('y1', 450)
                    .attr('x2', damageStart / 2 + 350 + i * 100 + 50)
                    .attr('y2', 445)
                    .attr('marker-start', 'url(#circle-point)')
                    .attr('marker-end', 'url(#arrowhead)')
                  g.append('rect')
                    .attr('x', 660)
                    .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 200)
                    .attr('width', 30)
                    .attr('height', 30)
                    .attr('rx', 5)
                    .attr(
                      'fill',
                      'url(#circle-image-' + (i * 4 + 7).toString() + ')'
                    )
                    .attr('class', 'hoverItem')
                    .on('click', function () {
                      self.bridgeDamageClickObject(i * 4 + 7)
                    })
                    .attr('transform', 'rotate(90 385 155)')
                }
                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 100)
                  .attr('x2', damageStart / 2 + 350 + i * 100 - 20)
                  .attr('y2', 120)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 360)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 300)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' + (i * 4 + 2).toString() + ')'
                  )
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject(i * 4 + 2)
                  })
                  .attr('transform', 'rotate(90 385 155)')
                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 450)
                  .attr('x2', damageStart / 2 + 350 + i * 100 - 20)
                  .attr('y2', 430)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 640 - 20)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 300)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' +
                      (
                        3 +
                        (this.bridgePart.length - 2) * 4 +
                        (this.bridgePart.length - 3 - i) * 4 +
                        4
                      ).toString() +
                      ')'
                  )
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject(
                      3 +
                        (self.bridgePart.length - 2) * 4 +
                        (self.bridgePart.length - 3 - i) * 4 +
                        4
                    )
                  })
                  .attr('transform', 'rotate(90 385 155)')

                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 100)
                  .attr('x2', damageStart / 2 + 350 + i * 100)
                  .attr('y2', 140)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 375)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 255)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' + (i * 4 + 3).toString() + ')'
                  )
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject(i * 4 + 3)
                  })
                  .attr('transform', 'rotate(90 385 155)')
                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 450)
                  .attr('x2', damageStart / 2 + 350 + i * 100)
                  .attr('y2', 410)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 640 - 35)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 255)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' +
                      (
                        3 +
                        (this.bridgePart.length - 2) * 4 +
                        (this.bridgePart.length - 3 - i) * 4 +
                        2
                      ).toString() +
                      ')'
                  )
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject(
                      3 +
                        (self.bridgePart.length - 2) * 4 +
                        (self.bridgePart.length - 3 - i) * 4 +
                        2
                    )
                  })
                  .attr('transform', 'rotate(90 385 155)')

                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 100)
                  .attr('x2', damageStart / 2 + 350 + i * 100)
                  .attr('y2', 170)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 410)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 255)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' + (i * 4 + 4).toString() + ')'
                  )
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject(i * 4 + 4)
                  })
                  .attr('transform', 'rotate(90 385 155)')
                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 450)
                  .attr('x2', damageStart / 2 + 350 + i * 100)
                  .attr('y2', 380)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 640 - 70)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 255)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' +
                      (
                        3 +
                        (this.bridgePart.length - 2) * 4 +
                        (this.bridgePart.length - 3 - i) * 4 +
                        3
                      ).toString() +
                      ')'
                  )
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject(
                      3 +
                        (self.bridgePart.length - 2) * 4 +
                        (self.bridgePart.length - 3 - i) * 4 +
                        3
                    )
                  })
                  .attr('transform', 'rotate(90 385 155)')
                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 100)
                  .attr('x2', damageStart / 2 + 350 + i * 100 + 16)
                  .attr('y2', 115)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 345)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 235)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' + (i * 4 + 5).toString() + ')'
                  )
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject(i * 4 + 5)
                  })
                  .attr('transform', 'rotate(90 385 155)')
                g.append('line')
                  .style('stroke', 'rgb(149,55,53)')
                  .style('stroke-width', 3)
                  .attr('x1', damageStart / 2 + 350 + i * 100)
                  .attr('y1', 450)
                  .attr('x2', damageStart / 2 + 350 + i * 100 + 16)
                  .attr('y2', 435)
                  .attr('marker-start', 'url(#circle-point)')
                  .attr('marker-end', 'url(#arrowhead)')
                g.append('rect')
                  .attr('x', 635)
                  .attr('y', 0 - (damageStart / 2 + 100) - i * 100 + 235)
                  .attr('width', 30)
                  .attr('height', 30)
                  .attr('rx', 5)
                  .attr(
                    'fill',
                    'url(#circle-image-' +
                      (
                        3 +
                        (this.bridgePart.length - 2) * 4 +
                        (this.bridgePart.length - 3 - i) * 4 +
                        1
                      ).toString() +
                      ')'
                  )
                  .attr('class', 'hoverItem')
                  .on('click', function () {
                    self.bridgeDamageClickObject(
                      3 +
                        (self.bridgePart.length - 2) * 4 +
                        (self.bridgePart.length - 3 - i) * 4 +
                        1
                    )
                  })
                  .attr('transform', 'rotate(90 385 155)')
              }
            }
          }
        } else {
          const all = document.getElementsByClassName('hoverItem')
          for (let i = 0; i < all.length; i++) {
            all[i].style.cursor = 'pointer'
          }
        }
        if (this.disableDirection === false) {
          if (this.bridgeDirection === 'right') {
            g.append('line')
              .style('stroke', 'black')
              .style('stroke-width', 3)
              .attr('x1', 300)
              .attr('y1', 50)
              .attr('x2', end - 100)
              .attr('y2', 50)
              .attr('marker-end', 'url(#triangle_fwd)')
          } else if (this.bridgeDirection === 'left') {
            g.append('line')
              .style('stroke', 'black')
              .style('stroke-width', 3)
              .attr('x1', 300)
              .attr('y1', 60)
              .attr('x2', end - 100)
              .attr('y2', 60)
              .attr('marker-start', 'url(#triangle_bwd)')
          }
          g.append('text')
            .text('ทิศทางจราจร')
            .attr('x', (end + 100) / 2)
            .attr('y', 30)
            .attr('class', 'textLayer')
          g.append('text')
            .text('กม. น้อย')
            .attr('x', 300)
            .attr('y', 550)
            .attr('class', 'textLayer')
          g.append('text')
            .text('กม. มาก')
            .attr('x', end - 150)
            .attr('y', 550)
            .attr('class', 'textLayer')
          g.append('text')
            .text('ไป ' + this.startLocation)
            .attr('x', 300)
            .attr('y', 500)
            .attr('class', 'textLayer')
          g.append('text')
            .text('ไป ' + this.endLocation)
            .attr('x', end - 150)
            .attr('y', 500)
            .attr('class', 'textLayer')
          g.append('line')
            .style('stroke', 'black')
            .style('stroke-width', 3)
            .attr('x1', 250)
            .attr('y1', 470)
            .attr('x2', 400)
            .attr('y2', 470)
            .attr('marker-start', 'url(#triangle_bwd)')
          g.append('line')
            .style('stroke', 'black')
            .style('stroke-width', 3)
            .attr('x1', end - 200)
            .attr('y1', 470)
            .attr('x2', end - 50)
            .attr('y2', 470)
            .attr('marker-end', 'url(#triangle_fwd)')
        }
        g.append('svg:defs')
          .append('svg:marker')
          .attr('id', 'triangle_fwd')
          .attr('refX', 2)
          .attr('refY', 2)
          .attr('markerWidth', 30)
          .attr('markerHeight', 30)
          .attr('orient', '0deg')
          .append('path')
          .attr('d', 'M 0 0 4 2 0 4 1 2')
          .style('fill', 'black')
        g.append('svg:defs')
          .append('svg:marker')
          .attr('id', 'triangle_bwd')
          .attr('refX', 2)
          .attr('refY', 2)
          .attr('markerWidth', 30)
          .attr('markerHeight', 30)
          .attr('orient', '180deg')
          .append('path')
          .attr('d', 'M 0 0 4 2 0 4 1 2')
          .style('fill', 'black')
        g.append('svg:defs')
          .append('svg:marker')
          .attr('id', 'arrowhead')
          .attr('refX', 0)
          .attr('refY', 1.25)
          .attr('markerWidth', 5)
          .attr('markerHeight', 35)
          .attr('orient', 'auto')
          .append('polygon')
          .attr('points', '0 0, 3.75 1.25, 0 2.5')
          .style('fill', 'rgb(149,55,53)')
        g.append('svg:defs')
          .append('svg:marker')
          .attr('id', 'circle-point')
          .attr('refX', 3)
          .attr('refY', 3)
          .attr('markerWidth', 5)
          .attr('markerHeight', 5)
          .append('circle')
          .attr('cx', 3)
          .attr('cy', 3)
          .attr('r', '1.5')
          .style('fill', 'rgb(149,55,53)')

        g.append('svg:defs')
          .append('svg:clipPath')
          .attr('id', 'clipPath')
          .attr('x', 100)
          .attr('y', 100)
          .attr('r', 50)
        g.attr('d', 'M 0 0 4 2 0 4 1 2')
        this.finishLoading = true
        setTimeout(() => {
          const bounds = g.node().getBBox()
          const zoom = d3
            .zoom()
            .scaleExtent([0.05, 5])
            .on('zoom', this.handleZoom)
          this.globalWidth = bounds.width / 2 + 250
          this.globalHeight = bounds.height / 2 + 250
          const transform = this.zoomFit(g)
          d3.select('#bridge').call(zoom).call(zoom.transform, transform)
          d3.select('#bridge').on('dblclick.zoom', null)
        }, 100)
      }
    },
    async getBridgeSegment (bridgeCode, partCode, seq) {
      const bridgeSegmentResult = await this.$store.dispatch(
        'getBridgeSegment',
        {
          bridgeCode,
          partCode,
          seq
        }
      )
      if (bridgeSegmentResult == null) {
        return false
      } else {
        return true
      }
    },
    bridgeComponentClick (component) {
      if (this.accidentMode === false) {
        this.$emit('clicked', component)
      }
    },
    drawCheckMark (g, isCompleted, x, y, height, clickParams, id) {
      const self = this
      if (isCompleted === 'completed') {
        g.append('svg:image')
          .attr('x', x)
          .attr('y', y)
          .on('mouseover', self.mouseovered(true, id))
          .on('mouseout', self.mouseovered(false, id))
          .attr('class', 'hoverItem')
          .attr('height', height)
          .attr('xlink:href', this.imgPath.checkMarkGreen)
          .on('click', function () {
            self.changeColor(id)
            self.bridgeComponentClickObject(
              clickParams.sectorID,
              clickParams.sectorNo,
              clickParams.componentID,
              clickParams.componentNo,
              clickParams.text,
              clickParams.back,
              clickParams.action
            )
          })
      } else if (isCompleted === 'in progress') {
        g.append('svg:image')
          .attr('x', x)
          .attr('y', y)
          .attr('class', 'hoverItem')
          .attr('height', height)
          .on('mouseover', self.mouseovered(true, id))
          .on('mouseout', self.mouseovered(false, id))
          .attr('xlink:href', this.imgPath.checkMarkMinus)
          .on('click', function () {
            self.changeColor(id)
            self.bridgeComponentClickObject(
              clickParams.sectorID,
              clickParams.sectorNo,
              clickParams.componentID,
              clickParams.componentNo,
              clickParams.text,
              clickParams.back,
              clickParams.action
            )
          })
      } else {
        g.append('svg:image')
          .attr('x', x)
          .attr('y', y)
          .attr('class', 'hoverItem')
          .attr('height', height)
          .on('mouseover', self.mouseovered(true, id))
          .on('mouseout', self.mouseovered(false, id))
          .attr('xlink:href', this.imgPath.checkMarkGrey)
          .on('click', function () {
            self.changeColor(id)
            self.bridgeComponentClickObject(
              clickParams.sectorID,
              clickParams.sectorNo,
              clickParams.componentID,
              clickParams.componentNo,
              clickParams.text,
              clickParams.back,
              clickParams.action
            )
          })
      }
    },
    async bridgeComponentClickObject (
      sectorID,
      sectorNo,
      componentID,
      componentNo,
      text,
      back,
      action
    ) {
      let backCheck = back
      if (
        this.bridgeType === 'Box Culvert' ||
        this.bridgeType === 'Box Underpass'
      ) {
        backCheck = false
      }
      const result = {
        sectorID: sectorID,
        sectorNo: sectorNo,
        componentID: componentID,
        componentNo: componentNo,
        text: text,
        back: backCheck,
        action: action
      }
      this.$emit('clicked', result)
    },
    bridgeDamageClickObject (seq) {
      let seqDetail = ''
      let currentImage = ''
      let imageId = -1
      for (let i = 0; i < this.routineDamageImage.length; i++) {
        if (parseInt(this.routineDamageImage[i].seq) === parseInt(seq)) {
          seqDetail = this.routineDamageImage[i].description
          currentImage = this.routineDamageImage[i].filepath_image
          imageId = this.routineDamageImage[i].id
        }
      }
      const result = {
        seq: seq,
        seqDetail: seqDetail,
        currentImage: currentImage,
        imageId: imageId,
        back: false,
        action: false,
        damage: true
      }
      this.$emit('clicked', result)
    }
  },
  watch: {
    value () {
      this.valueInside = this.value
    },
    valueInside () {
      this.$emit('input', this.valueInside)
    },
    bridgeType () {
      this.initZoom()
    },
    bridgeView () {
      this.initZoom()
    }
  }
}
</script>
<style>
.hide {
  display: none;
}
.hoverItem {
  cursor: pointer;
}
</style>
<style scoped lang="scss">
img,
span {
  position: absolute;
}
.profile-view {
  position: relative;
  height: 100%;
}
.spn-center {
  top: 180px;
}
.spn-joint {
  top: 4px;
}
.center.first.wing {
  left: 9px;
}
</style>
